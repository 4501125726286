import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import SkillItem from "./SkillItem";

export default function SkillItemListView({
  eventId,
  stationId,
  evaluatedId,
  debriefingId,
  checklistId,
  skillId,
  items,
  loadChecklistDebriefing,
}: any) {
  useEffect(() => {}, [checklistId, skillId, debriefingId, items]);

  return (
    <Box>
      {items.map((item, index) => (
        <SkillItem
          key={index}
          item={item}
          eventId={eventId}
          stationId={stationId}
          evaluatedId={evaluatedId}
          debriefingId={debriefingId}
          checklistId={checklistId}
          skillId={skillId}
          loadChecklistDebriefing={loadChecklistDebriefing}
        />
      ))}
    </Box>
  );
}
