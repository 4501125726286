import api from "../config/api";
import IChecklistData from "../types/Checklist";

const store = (data: any) => {
  return api.post<IChecklistData>("/checklists", data);
};

const getAll = (page = 1, paginate = 0) => {
  return api.get<IChecklistData>(
    "/checklists?page=" + page + "&paginate=" + paginate
  );
};

const get = (checklistId: number) => {
  return api.get("/checklists/" + checklistId);
};

const remove = (id: any) => {
  return api.delete("/checklists/" + id);
};

const update = (id: any, data: any) => {
  return api.put<IChecklistData>("/checklists/" + id, data);
};

const getSkills = (checklistId: any) => {
  return api.get<IChecklistData>(`/checklist/${checklistId}/skills`);
};

const getSkillItens = (checklistId: any, skillId: any) => {
  return api.get<any>(`/checklist/${checklistId}/skill/${skillId}/items`);
};

const importChecklist = (id: any) => {
  return api.get<IChecklistData>(`/checklists/${id}/import`);
};

const getChecklistDebriefing = (idChecklist: any, idDebriefing: any) => {
  return api.get<IChecklistData>(
    `/checklist/${idChecklist}/debriefing/${idDebriefing}`
  );
};

const CheckListService = {
  store,
  getAll,
  get,
  remove,
  update,
  getSkills,
  getSkillItens,
  importChecklist,
  getChecklistDebriefing,
};

export default CheckListService;
